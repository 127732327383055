<template>
  <div class="workArea-home">
    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <div class="oSwiper" v-for="(item, index) in bannerList" :key="index">
          <van-swipe-item><img :src="item.url" alt="" /></van-swipe-item>
        </div>
      </van-swipe>
    </div>
    <div class="iconList">
      <div
        class="item"
        v-for="(item, index) in iconList"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="item-t"><img :src="item.url" alt="" /></div>
        <div class="item-b">{{ item.text }}</div>
      </div>
    </div>
    <!-- <div class="sign">
      <div class="daySign-c">
        <div class="body">
          <div class="daySign-ct" v-if="postData.todayIsSignIn">
            已连续签到<span>{{ postData.continuousSignInCount }}</span
            >天
          </div>
          <div class="daySign-ct" v-else>
            已连续签到<span>{{ postData.continuousSignInCount }}</span
            >天
          </div>
          <div class="daySign-cb">
            <div class="item" v-for="(item, index) in signData" :key="index">
              <div class="item-t">
                <div class="item-tl active" v-if="item.doSign">
                  <span>+{{ item.willGetScore }}</span>
                </div>
                <div class="item-tl" v-if="!item.doSign">
                  <span>+{{ item.willGetScore }}</span>
                </div>
                <div
                  :class="[
                    'item-tr',
                    {
                      active: returnStatus(item, index),
                    },
                  ]"
                  v-if="index != signData.length - 1"
                ></div>
              </div>
            </div>
          </div>
          <div class="opera">
            <div class="opera-l" v-if="!postData.todayIsSignIn" @click="tosign">
              签到
            </div>
            <div class="opera-r" v-else>已签到</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="main">
      <div class="title">
        <div class="title-l">创业管家</div>
        <div class="title-r" @click="toTeacher">
          <div class="txt">更多</div>
          <div class="img"><img src="./img/arrow.png" alt="" /></div>
        </div>
      </div>
      <div class="teacher">
        <div class="oList" v-for="(item, index) in teacherList" :key="index">
          <div class="teacher-t"><img :src="item.picture" alt="" /></div>
          <div class="teacher-c">
            <div class="txt">{{ item.name }}</div>
            <div class="img"><img src="./img/medal.png" alt="" /></div>
          </div>
          <div class="teacher-b">共服务{{ item.serveCount }}次</div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <div class="title-l">服务联盟</div>
        <div class="title-r" @click="toLeague">
          <div class="txt">更多</div>
          <div class="img"><img src="./img/arrow.png" alt="" /></div>
        </div>
      </div>
      <div class="service">
        <div
          class="oList"
          v-for="(item, index) in leagueList"
          :key="index"
          @click="toLeagueDetail(item)"
        >
          <div class="service-t"><img :src="item.picture" alt="" /></div>
          <div class="service-c">
            <div class="txt">{{ item.name }}</div>
            <div class="label">{{ item.serviceType }}</div>
          </div>
          <div class="service-b">
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
var moment = require("moment");
import {
  getTeacherListUrl,
  signUrl,
  dayMaskUrl,
  getLeagueListUrl,
} from "./api.js";
export default {
  name: "workArea-home",
  data() {
    return {
      postData: {},
      signData: [],
      bannerList: [{ url: require("./img/banner.png") }],
      teacherList: [],
      leagueList: [],
      iconList: [
        // {
        //   url: require("./img/createComp.png"),
        //   text: "开企业",
        //   code: 1,
        //   isshow: true,
        // },
        {
          url: require("./img/workArea.png"),
          text: "众创空间",
          code: 1,
          toUrl: "rentPlace",
          isshow: true,
        },
        // {
        //   url: require("./img/policy.png"),
        //   text: "政法法规",
        //   code: 1,
        //   isshow: true,
        // },
        {
          url: require("./img/teacther.png"),
          text: "创业管家",
          code: 1,
          toUrl: "createWorkTeacher",
          isshow: true,
        },
        {
          url: require("./img/league.png"),
          text: "服务联盟",
          code: 1,
          toUrl: "serviceLeague",
          isshow: true,
        },
        {
          url: require("./img/talent.png"),
          text: "创业达人",
          code: 1,
          toUrl: "createWorkTalent",
          isshow: true,
        },
      ],
      pageSize: 5,
      curPage: 1,
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {
    this.getTeacherList();
    this.getTalentList();
    gloabalCount("", 33, 1);
  },
  methods: {
    toLeagueDetail(item) {
      this.$router.push({
        name: "serviceLeagueForm",
        query: { id: item.id },
      });
    },
    toLeague() {
      this.$router.push({
        name: "serviceLeague",
      });
    },
    toTeacher() {
      this.$router.push({
        name: "createWorkTeacher",
      });
    },
    getTalentList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(`${getLeagueListUrl}`, { params: params }).then((res) => {
        if (res.code === 200) {
          res.data.records.forEach((item) => {
            if (item.activityPicture) {
              item.activityPicture = this.$handleImg(
                400,
                300,
                item.activityPicture
              );
            }
            this.leagueList.push(item);
          });
        } else {
        }
      });
    },
    getTeacherList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .get(`${getTeacherListUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = this.$handleImg(
                  400,
                  300,
                  item.activityPicture
                );
              }
              this.teacherList.push(item);
            });
          } else {
          }
        });
    },
    toDetail(item) {
      if (!item.toUrl) {
        this.$toast({
          message: "敬请期待",
          duration: 2000,
        });
      }
      this.$router.push({
        name: item.toUrl,
      });
    },
    async tosign() {
      if (await toRegister(this.$route.path, this.$route.query, "每日签到")) {
        this.sign();
      }
    },
    sign() {
      let params = {
        userId: this.userId,
        tenant: this.tenantId,
        communityId: this.communityId,
        roomId: this.roomId,
      };
      this.$axios
        .post(`${signUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.points = res.data.pointCount;
            this.$toast({
              message: `恭喜你获得${this.points}积分`,
              duration: 2000,
            });
            this.getMask();
          } else {
          }
        });
    },
    getMask() {
      let params = {
        userId: this.userId,
        tenant: this.tenantId,
      };
      this.$axios
        .post(`${dayMaskUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          this.signData = [];
          if (res.code === 200) {
            this.postData = res.data;
            let oData = res.data.signData;
            if (oData.scoreList) {
              let arr = oData.scoreList.split("");
              console.log(arr, 345);
              arr.forEach((element, index) => {
                let obj = {};
                if (index < this.postData.continuousSignInCount) {
                  obj.doSign = true;
                } else {
                  obj.doSign = false;
                }
                obj.willGetScore = element;
                this.signData.push(obj);
              });
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.workArea-home {
  background: #fefefe;
  min-height: 100vh;
  .banner {
    width: 100%;
    height: 334px;
    margin-bottom: 40px;
    .oSwiper {
      width: 100%;
      .van-swipe-item {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .iconList {
    padding: 0 42px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 142px;
      height: 170px;
      margin-bottom: 16px;
      border-radius: 6px;
      text-align: center;
      .item-t {
        width: 100%;
        height: 142px;
        margin-bottom: 10px;
        img {
          height: 100%;
        }
      }
      .item-b {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 28px;
      }
    }
  }
  .sign {
    .daySign-c {
      padding: 32px;
      .body {
        height: 256px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 16px;
        position: relative;
        padding: 46px 32px 48px;
        .daySign-ct {
          font-size: 26px;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          margin-bottom: 34px;
          display: flex;
          align-items: center;
          span {
            font-size: 40px;
            font-weight: bold;
            color: #007eff;
            margin: 0 6px;
          }
        }
        .daySign-cb {
          display: flex;
          .item-t {
            display: flex;
            align-items: center;
            .item-tl {
              width: 56px;
              height: 56px;
              img {
                width: 100%;
                height: 100%;
              }
              span {
                display: block;
                width: 52px;
                font-size: 28px;
                height: 52px;
                background: #efefef;
                line-height: 52px;
                font-weight: bold;
                color: #b3b3b3;
                text-align: center;
                border-radius: 50%;
                border: 2px solid #d1d1d1;
              }
            }
            .active {
              background: rgba(0, 126, 255, 0.5);
              color: rgba(0, 126, 255, 1);
              border-radius: 50%;
              span {
                background: #b8cdf6;
                color: rgba(0, 126, 255, 1);
                border: 2px solid rgba(0, 126, 255, 1);
              }
            }
            .item-tr {
              width: 38px;
              height: 4px;
              background: rgba(209, 209, 209, 1);
              border-radius: 2px;
            }
            .active.item-tr {
              background: #007eff;
            }
          }
          .item-b {
            font-size: 24px;
            margin-top: 2px;
            font-weight: 400;
            color: #aaaaaa;

            line-height: 34px;
            .item-b-c {
              width: 56px;
              text-align: center;
            }
          }
        }
        .opera-l,
        .opera-r {
          width: 128px;
          height: 64px;
          background: #fe6f16;
          border-radius: 48px;
          font-weight: bold;
          color: #ffffff;
          line-height: 64px;
          font-size: 28px;
          text-align: center;
          position: absolute;
          top: 32px;
          right: 32px;
        }
        .opera-r {
          background: #ededed;
          color: #cccccc;
        }
      }
    }
  }
  .main {
    padding: 0 28px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 32px 0;
      .title-l {
        font-size: 36px;
        font-weight: 600;
        color: #1a1c34;
        line-height: 50px;
        position: relative;
        margin-left: 12px;
        z-index: 10;
      }
      .title-l::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        content: "";
        width: 120%;
        height: 16px;
        background: #e2e2e2;
        border-radius: 8px;
      }
      .title-r {
        display: flex;
        align-items: center;
        .txt {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          margin-right: 6px;
        }
        .img {
          width: 12px;
          height: 18px;
          font-size: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .teacher {
      display: flex;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      padding: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
      .oList {
        width: 248px;
        flex-shrink: 0;
        height: 320px;
        padding-top: 26px;
        margin-right: 46px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        .teacher-t {
          width: 166px;
          height: 166px;
          margin: 0 auto;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .teacher-c {
          display: flex;
          align-items: center;
          margin: 28px auto 16px;
          justify-content: center;
          .txt {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            line-height: 40px;
            margin-right: 6px;
          }
          .img {
            width: 26x;
            height: 28px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .teacher-b {
          text-align: center;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.25);
          line-height: 22px;
        }
      }
    }
    .service {
      display: flex;
      flex-wrap: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      padding: 10px;
      &::-webkit-scrollbar {
        display: none;
      }
      .oList {
        width: 464px;
        flex-shrink: 0;
        height: 348px;
        margin-right: 40px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 16px;
        .service-t {
          height: 184px;
          img {
            border-radius: 16px 16px 0 0;
            width: 100%;
            height: 100%;
          }
        }
        .service-c {
          padding: 20px 28px 12px 28px;
          box-sizing: border-box;
          margin: 6px 0;
          display: flex;
          align-items: center;
          .txt {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 32px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
            margin-right: 6px;
          }
          .label {
            font-size: 20px;
            font-weight: 600;
            width: 142px;
            height: 38px;
            border-radius: 18px;
            border: 3px solid #615dff;
            line-height: 35px;
            text-align: center;
            font-size: 24px;
            color: #5e5aff;
            box-sizing: border-box;
          }
        }
        .service-b {
          padding: 0 28px 0 28px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
<style lang="less">
.workArea-home {
}
</style>
