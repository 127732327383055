let origin = "https://future.zhuneng.cn";
//签到状态
const signStatusUrl = `/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//签到
const signUrl = `/gateway/hc-accumulated/dailyPerf/doSignIn`;
//每日任务
const dayMaskUrl = `/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//是否显示评论
const isShowSign = `/gateway/hc-accumulated/application/getFunctionSetting`;
//创业导师列表
const getTeacherListUrl = `/gateway/hc-business/miniapi/advisor/getList`;
//服务联盟列表
const getLeagueListUrl = `/gateway/hc-business/miniapi/league/getList`;
export {
  signStatusUrl,
  signUrl,
  dayMaskUrl,
  isShowSign,
  getTeacherListUrl,
  getLeagueListUrl,
};
